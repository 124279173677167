/**
 * Positioning shorthand mixin.
 */
@mixin position($position, $top, $right, $bottom, $left) {
	position: $position;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}

// shorthands
@mixin absolute($top, $right, $bottom, $left) {
	@include position(absolute, $top, $right, $bottom, $left);
}

@mixin fixed($top, $right, $bottom, $left) {
	@include position(fixed, $top, $right, $bottom, $left);
}
