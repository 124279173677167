.wld-slider {
	display: flex;
	flex-direction: row;
	justify-content: center;

	&__linkTile {
		@include ease(opacity, $easeDuration2);
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		padding-top: $newsSliderArrow;

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}

		&--altColor {
			color: $invertedColor;
		}
	}

	&__bgImg {
		min-height: $fullScreenHeight;
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0;
		max-width: 100%;
		position: absolute;
		right: 0;
		top: 0;
		z-index: -1;

		img,
		picture {
			@include objectFit(cover, center);
			height: 100%;
			object-fit: cover;
			object-position: center;
			width: 100%;
			// min-width: $fullScreenWidth;
			// object-fit: contain;
			// object-position: center;
		}
	}

	&__slide {
		display: flex;
		width: 100%;
		height: 100%;

		&--news {
			align-items: baseline;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: flex-start;
			padding: $mainPadding2 $mainPadding1;
			@include media('>xSmall') {
				padding-left: $mainPadding2;
				padding-right: $mainPadding2;
			}
			@include media('>xLarge') {
				padding-left: $mainPadding3;
				padding-right: $mainPadding3;
			}
		}

		&--content {
			flex-direction: column;
			min-height: $fullScreenHeight;
			overflow: hidden;
			position: relative;
			width: 100%;
		}
	}

	&__upperSection {
		display: flex;
		align-items: stretch;
		flex-direction: row;
		flex-grow: 1;
	}

	&__content {
		@include ease(opacity z-index, $easeDuration6, $easeOutCubic);
		display: flex;
		flex-direction: column;
		opacity: 0;
		touch-action: pan-y;
		width: 100%;
		z-index: 1;
		// loops througth child elements of slider__content and stacks them
		@for $i from 1 through 30 {
			&:nth-child(#{$i + 1}) {
				transform: translate(-100% * $i);
			}
		}

		&:nth-child(1) {
			transform: translate(0);
		}

		&.wld-js-selected {
			@include ease(opacity z-index, $easeDuration6, $easeOutCubic);
			opacity: 1;
			z-index: 3;
		}

		&--news {
			height: 100%;
			justify-content: flex-start;
			min-width: 100%;
			padding-bottom: $mainPadding2;
		}

		&--content {
			background-color: $invertedBgColor;
			justify-content: flex-end;
			min-width: 100%;
			position: relative;
			// height: $fullScreenHeight;
		}

		&--altColorContent {
			color: $baseColor;
			background-color: $baseBgColor;
		}
	}

	&__date {
		@include wld-fontSize(small);
	}

	&__newsText {
		@include plantinRegular;
		@include wld-fontSize(medium);
	}

	&__contentWrapper {
		// sum of slider navs`s height,margin,padding,border
		margin-bottom: $base2 + $base2 + 0.1rem;
		margin-left: $mainMargin1;
		margin-top: $base6;
		flex-grow: 1;
		display: flex;
		justify-content: flex-end;
		flex-direction: column;
		z-index: 2;
		@include media('>xSmall') {
			margin-left: $mainMargin2;
		}
		@include media('>xLarge') {
			margin-left: $mainMargin3;
		}
	}
	// &::after {
	// 	background-color: $bgFilter;
	// 	bottom: 0;
	// 	height: 100% * 2;
	// 	left: 0;
	// 	position: absolute;
	// 	right: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	z-index: 2;
	// }
	&__headerText {
		max-width: $base2 * 10;
		padding-right: $mainPadding2;
		padding-bottom: $mainPadding2 / 2;
		@include wld-fontSize(h2);
	}

	&__contentSliderHeadline {
		@include wld-fontSize(h2);
		margin-bottom: $mainMargin2;
		color: $invertedColor;
	}

	&__sliderNav {
		&--news {
			width: 100%;
		}

		&--content {
			bottom: 0;
			left: 50%;
			position: absolute;
			transform: translate(-50%);
			z-index: 3;
		}
	}

	&__text {
		@include media('>medium') {
			max-width: $textMaxWidth1;
		}

		&--practiceContent {
			color: $invertedColor;
			@include plantinRegular;
			@include wld-fontSize(h2);
		}

		&--thematik {
			color: $invertedColor;
			margin: 0;
		}

		&--altColor {
			color: $invertedColor;
		}
	}

	&__icon {
		margin-right: $base / 2;

		span[data-type="svg"] {
			width: $base;
			@include media('>xLarge') {
				width: $base + 1;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}
	}

	&__wrapper {
		align-items: stretch;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		padding-left: $base / 2;
		@include media('>xSmall') {
			max-width: $textMaxWidth1;
			padding-left: $base;
		}
		@include media('>medium') {
			max-width: $textMaxWidth1;
		}
	}
}
