.wld-logos {
	background-color: $contentBgColor;
	padding: $mainPadding1 $mainPadding1 $mainMargin1;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}

	@include media('>xLarge') {
		padding: $base1 $mainPadding3;
	}

	&__logo {
		flex-grow: 0;
		flex-shrink: 0;
		height: $base3;
		margin-bottom: $mainPadding1;
		margin-top: $mainPadding1;
		padding-left: $base1;
		padding-right: $base1;

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}

		@include media('>xLarge') {
			height: 7vw;
			min-height: $base4;
			padding-bottom: $mainMargin1;
			padding-top: $mainMargin1;
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
			width: 20%;

			&:first-of-type {
				padding-left: $mainPadding2;
			}

			&:last-of-type {
				padding-right: $mainPadding2;
			}
		}
	}

	&__logoImage {
		position: relative;
		height: 100%;

		@include media('>xLarge') {
			width: 100%;
			height: 100%;
		}

		img {
			height: 100%;
			max-width: 200px;
			object-fit: contain;

			@include media('>xLarge') {
				display: block;
				height: 100%;
				left: 0;
				max-width: none;
				object-fit: contain;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		canvas {
			display: none;
		}
	}


	&__logos {
		overflow: hidden;
		position: relative;

		@include media('>xLarge') {
			overflow: visible;
		}
	}

	&__logosList {
		align-items: stretch;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-start;

		@include media('>xLarge') {
			display: flex;
			flex-wrap: wrap;
			height: auto;
			margin-bottom: $mainPadding1;
			margin-top: $mainPadding1;
			transform: none !important; //this overrides transform set by impetus from js
		}

		html.no-js & {
			flex-wrap: wrap;
			height: auto;
			padding-bottom: 0;
		}
	}


	&__navigation {
		@include media('>xLarge') {
			display: none;
		}
	}


	&__navigationButton {
		display: none;

		span[data-type="svg"] {
			pointer-events: none;
			width: $base;
			@include media('>xLarge') {
				width: $base + 1;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}

		&.wld-js-enabled {
			display: block;
		}
	}

	&__navigationItem {
		@include center(vertical);
		position: absolute;
		width: $base;
		height: $base;

		@include media('>xLarge') {
			width: $base + 1;
		}

		&--left {
			left: 0;
		}

		&--right {
			right: 0;
		}
	}

	&__text {
		@include wld-fontSize(small);
	}
}
