.wld-blockPublication {
	display: flex;
	flex-direction: column-reverse;
	justify-content: flex-end;

	.wld-blocks > & {
		@include media('>small') {
			display: block;
		}
	}

	&__link {
		@include ease(border-bottom-color, $hoverDuration);
		border-bottom: 1px solid transparent;

		&:focus,
		&:hover {
			border-bottom-color: currentColor;
		}
	}
}
