.wld-content {
	background-color: $contentBgColor;
	display: flex;
	flex-flow: column;
	padding-top: 0;
	padding-bottom: $mainPadding2;
	@include media('>xSmall') {
		flex-flow: row;
		padding-top: $base2;
		padding-bottom: $mainPadding2;
	}

	&__imgWrapper {
		flex-shrink: 0;
		flex-grow: 0;
		// min-width: 100%;
		width: 100%;
		@include media('>xSmall') {
			width: 50%; //$contentImageWidth;
			// min-width: $contentImgWrapper;
		}

		&--alignLeft {
			order: 0;
			// padding-right: 0;
			@include media('>xSmall') {
				// padding-right: $base;
			}
		}

		&--alignRight {
			order: 0;
			// padding-left: 0;
			@include media('>xSmall') {
				order: 1;
				// padding-left: $base;
				// max-width: $contentImageWidth;
			}
		}

		&--noImage {
			display: none;
		}
	}

	&__contentImg {
		@include wld-animateOnIntersection();
		max-width: 100%;
		min-height: 100%;
		@include media('>xSmall') {
			min-height: 50vh;
			max-width: 50vw;
		}

		img {
			width: 100%;
		}

		canvas {
			display: none;
		}
	}

	&__textWrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		@include media('>xSmall') {
			flex-grow: 0;
			flex-shrink: 0;
			width: 50%;
		}

		&--alignLeft {
			// min-width: $contentTextWidth;
			order: 0;
			padding-left: $mainPadding1;
			padding-right: $mainPadding2;
			padding-top: $mainPadding2;
			@include media('>xSmall') {
				// order: 0;
				// width: $contentTextWidth;
				padding-top: 0;
				padding-left: $mainPadding2;
			}
			@include media('>xLarge') {
				padding-left: $mainPadding3;
			}
		}

		&--alignRight {
			// max-width: 100%;
			order: 0;
			padding-left: $mainPadding1;
			padding-right: $mainPadding1;
			padding-top: $mainPadding2;
			@include media('>xSmall') {
				order: 0;
				// max-width: 50%;
				padding-top: 0;
				padding-right: $mainPadding2;
				padding-left: $base;
			}
			@include media('>xLarge') {
				padding-right: $mainPadding3;
			}
		}

		&--noImage {
			padding-left: $mainPadding1;
			padding-right: $mainPadding1;
			padding-top: $mainPadding2;
			width: 100%;
			@include media('>xSmall') {
				padding-left: $mainPadding2;
				padding-right: $mainPadding2;
			}
			@include media('>xLarge') {
				padding-left: $mainPadding3;
				padding-right: $mainPadding3;
			}
		}
	}

	&__text {
		@include wld-fontSize('content');

		&--alternativeFont {
			@include plantinRegular;
		}

		&--header {
			@include wld-fontSize('xMedium');
			padding-bottom: $mainPadding2;
		}

		&--content {

			figcaption,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				max-width: 100%;
				@include media('>xLarge') {
					max-width: $textMaxWidth2;
				}
			}
		}
	}
}
