.wld-contact {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-top: $mainPadding2;
	padding-bottom: $mainPadding2;

	&__textWrapper {
		width: 50%;
		display: flex;
		justify-content: flex-start;
		margin-bottom: $mainMargin2;

		padding-left: $mainPadding1;
		padding-right: $mainPadding1;

		@include media('>xSmall') {
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
		}
		@include media('>xSmall') {
			padding-left: $mainPadding3;
			padding-right: $mainPadding3;
		}

		// @include media('>small') {
		// 	// max-width: $contactText;
		// }
	}

	&__info {
		margin-right: $base;

		&:first-child {
			@include media('>medium') {
				// margin-right: $contactBoxMargin;
			}
		}
	}

	&__infoWrapper {
		flex-grow: 0;
		display: flex;

		padding-left: $mainPadding1;
		padding-right: $mainPadding1;

		@include media('>xSmall') {
			width: 50%;
			padding-left: $mainPadding1;
			padding-right: $mainPadding2;
		}
		@include media('>xSmall') {
			padding-left: $mainPadding1;
			padding-right: $mainPadding3;
		}

	}

	&__text {
		&--content {
			@include wld-fontSize(h2);
			@include plantinRegular;
			@include media('>xLarge') {
				max-width: $textMaxWidth1;
			}
		}

		&--contactText {
			@include wld-fontSize(small);
		}
	}

	&__link {
		@include ease(opacity, $easeDuration2);

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}
	}
}
