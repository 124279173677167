.wld-quoteBox {
	align-items: center;
	background-color: $contentBgColor;
	display: flex;
	flex-direction: column;
	padding-top: $mainPadding2;
	padding-bottom: $mainPadding2;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}

	&__text {
		@include wld-fontSize(h2);
		@include plantinRegular;
		max-width: $textMaxWidth1;
		text-align: center;

		&::before {
			content: '«';
			quotes: "«" "\00BB";
		}

		&::after {
			content: '»';
			quotes: "»" "\00AB";
		}
	}

	&__author {
		text-align: center;
	}
}
