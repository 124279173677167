.wld-block {
	&__text {
		margin-left: auto;
		margin-right: auto;
		// margin-bottom: $base2;

		&:last-child {
			margin-bottom: 0;
		}

		.wld-block__title + & {
			margin-top: $base3;

			@include media('>small') {
				margin-top: $base5;
			}
		}

		.wld-block--linkedTile &,
		.wld-block--teaser &,
		.wld-block--search & {
			@include wld-fontSize(h1);
		}

		&--important {
			@include wld-fontSize(important);
		}

		& + & {
			margin-top: $base2;
		}


		.wld-block--video & {
			margin-top: $base2;

			@include media('>small') {
				margin-top: $base4;
			}
		}

		.wld-block--video .wld-block__title + & {
			margin-top: 0;

			@include media('>small') {
				margin-top: 0;
			}
		}

		blockquote {
			margin-left: 0;
		}

		&--big {
			@include wld-fontSize(h1);
		}

		&--small {
			@include wld-fontSize(small);
		}

		&--heading {
			@include wld-fontSize(h1);
		}

		&--alignLeft {
			text-align: left;
		}
		&--alignCenter {
			text-align: center;
		}

		&--invertedColor {
			color: $invertedColor;
		}
	}
}
