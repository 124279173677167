.wld-introduction {
	background-color: $contentBgColor;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-top: $mainPadding2;
	padding-bottom: $mainPadding2;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}

	&__text {
		@include wld-fontSize(h3);
		@include media('>medium') {
			max-width: $textMaxWidth1;
		}

		&--link {
			@include wld-fontSize(small);
		}
	}

	span[data-type="svg"] {
		width: $base;
		margin-right: $base / 2;
		@include media('>xLarge') {
			width: $base + 1;
		}

		svg {
			fill: currentColor;
			stroke: currentColor;
		}
	}

	&__arrow {
		@include ease(opacity, $easeDuration);
		display: flex;
		margin-top: $base;

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}
	}
}
