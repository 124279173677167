body,
html {
	@include sansSerif();
}

body {
	overflow-y: scroll;
}

body.wld-js-menuNoScroll,
body.wld-js-noScroll {
	overflow: hidden;
	position: relative;
	height: 100%;
}
// keep the scrollbar visible during search to avoid ugly "jumps" on Windows, where the scrollbar take space in the viewport
body.wld-js-searchMode {
	overflow-y: scroll;
}

h1 {
	@include wld-fontSize(h1);
	// @include margin-bottom(h1);
}

h2 {
	@include wld-fontSize(h2);
	// @include margin-bottom(h1);
}

h3 {
	@include wld-fontSize(medium);
	// @include margin-bottom(h2);
}

h4,
h5,
h6 {
	@include wld-fontSize(small);
	// @include margin-bottom(base);
}

p {
	// @include margin-bottom($base1Half);
	&:last-child {
		margin-bottom: 0;
	}

	a {
		// font-weight: bold;
		text-decoration: none;
		// border-bottom: 1px solid currentColor;
		// @include ease(border-bottom-color, $hoverDuration);
		&:focus,
		&:hover {
			// opacity: $iconHover;
		}
	}
}

a {
	text-decoration: none;
	// @include margin-bottom(base);
	&:active,
	&:focus,
	&:hover,
	&:visited {
		// opacity: $iconHover;
	}
}

figcaption {
	@include wld-fontSize(caption);
	text-align: left;
	margin-top: $base;
}
