.wld-sideMenu {
	@include ease(transform visibility transform-origin, $easeDuration2);
	// display: none;
	background-color: $invertedColor;
	height: $fullScreenHeight;
	min-height: 100vh;
	min-width: $thirdOfScreenWidth * 2;
	overflow-y: auto;
	padding-top: $base / 2;
	position: absolute;
	top: 0;
	transform-origin: 0, 0;
	transform: translateX(- $thirdOfScreenWidth * 2);
	visibility: hidden;
	z-index: 0;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}
	@include media('>small') {
		min-width: $thirdOfScreenWidth;
	}

	&.wld-js-move {
		// display: block;
		transform-origin: - $thirdOfScreenWidth * 2, 0;
		transform: translateX(0);
		visibility: inherit;
		z-index: 4;
	}

	&__wrapper {
		margin-top: $sideMenuSpace1;
		@include media('>small') {
			margin-top: $sideMenuSpace2;
		}
		@include media('>xLarge') {
			margin-top: $sideMenuSpace3;
		}
	}

	&__link {
		@include ease(opacity, $easeDuration2);
		padding-bottom: $base / 2;

		&--subLink {
			p {
				margin-left: $mainMargin1;

				@include media('>xSmall') {
					margin-left: $mainMargin2;
				}
				@include media('>xLarge') {
					margin-left: $mainMargin3;
				}
			}
		}

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: 0.5;
		}
	}

	&__list {
		position: relative;
		@include ease(opacity color transform, $easeDuration2);
		// &.wld-js-moved {
		// 	transform: translate(0, - #{$mainPadding + 0.1rem + wld-getLineHeight()});
		// }
		//
		// &:not(.wld-js-moved) {
		// 	transform: translate(0, 0);
		// }
	}

	&__subElem {
		@include wld-fontSize(base);
		@include ease(opacity, $easeDuration2);
		border-top: 1px solid $baseColor;
		opacity: 0;
		padding-bottom: $base1Half;
		padding-top: $base1Half;
	}

	&__subMenu {
		overflow: hidden;
		@include ease(opacity, $easeDuration2);
	}

	&__subList {
		align-self: center;
		display: flex;
		flex-direction: column;
		@include ease(opacity, $easeDuration2);
		@include wld-fontSize(base);
		border-bottom: 1px solid $baseColor;
		cursor: pointer;
		padding-bottom: 0;
		padding-top: $base1Half;

		&--practice {
			padding-bottom: 0;
		}
	}

	&__subList[data-wld-collapsed="false"] &__subElem {
		@for $i from 1 through 6 {
			&:nth-of-type(6n + #{$i}) {
				@include ease(opacity, $easeDuration2 + $i * 0.5s);
				opacity: 1;
			}
		}
	}

	&__accordionItemBody {
		height: auto;
		overflow: hidden;
		position: relative;
	}

	&__accordionItemContent {
		@include fadeout();
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;

		[data-wld-accordion-item][data-wld-collapsed="false"] & {
			@include fadein;
			position: relative;
		}
	}
}
