.wld-overlay {
	display: none;
	left: 0;
	min-height: 100vh;
	padding: $base2 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
	@include fadeout($easeDuration2);
	@include media('>small') {
		padding: $base8 0;
	}

	&.wld-js-beforeActive {
		display: block;
	}

	&.wld-js-duringActive {
		@include fadein;
	}

	&__actions {
		@include wld-container;
		height: 0;
		max-width: $overlayMaxWidth;
		position: sticky;
		text-align: right;
		top: 0;
		z-index: 2;
		@include media('>small') {
			position: relative;
		}

		.wld-overlay.wld-js-largeWidth & {
			max-width: $overlayLargeMaxWidth;
		}
	}

	&__content {
		position: relative;

		.wld-overlay:not(.wld-js-beforeActive) & {
			height: 0;
			overflow: hidden;
		}
		@include wld-container;
		max-width: $overlayMaxWidth;

		.wld-overlay.wld-js-largeWidth & {
			max-width: $overlayLargeMaxWidth;
		}

		.wld-overlay--account & {}
	}

	&__page {
		@include wld-container();
		// margin-right: $base5;
		background-color: $baseBgColor;
		margin-left: 0;
		margin-right: 0;
		max-width: none;
		padding-top: $containerPaddingSmall;
		position: relative;
		width: auto;
		@include media('>small') {
			margin-right: $iconBtnHeight + $base;
			padding-top: $containerPadding;
		}
		// @for $i from 0 through 9 {
		$minI: $overPageActionCount; //if($i > 1, $i, 1);
		// &--btnCount#{$i} {
		min-height: $minI * $base5 - $base;
		@include media('>small') {
			min-height: $minI * $base8 - $base;
		}
		// 	}
		// }
		&--btnCount5 {
			min-height: $base4 * 5 + $base * 4;
			@include media('>small') {
				min-height: $base7 * 5 + $base * 4;
			}
		}
	}

	&__issuu {
		height: 0;
		padding-bottom: (1 / $videoPlayerRatio * 100%);
		position: relative;
		width: 100%;

		iframe {
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}
}
