@keyframes sideMenuFadeOut {
	from {
        opacity: 0;
        display: flex;
	}

	to {
        opacity: 1;
		display: none;
	}
}

@keyframes sideMenuFadeIn {
	from {
        opacity: 1;
        display: none;
	}

	to {
        opacity: 0;
        display: flex;
	}
}
