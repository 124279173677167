$base: 2.5rem;
$base0: $base * 0;
$base1Half: $base / 2;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;

$mainMaxWidth: $base * 140;
$overlayMaxWidth: $base * 112;
$overlayLargeMaxWidth: $base * 140;
$textMaxWidth1: 95%;
$textMaxWidth2: 75%;

// General
$fullScreenHeight: 100vh;
$fullScreenWidth: 100vw;
$introductionFontSize: 3rem;
$thirdOfScreenWidth: 33.3vw;
$thirdOfScreenHeight: 33.3vh;
$halfOfScreenHeight: 50vh;
$halfOfScreenWidth: 50vw;

// Blocks
$blockMobileVerticalPadding: $base2;
$blockVerticalPadding: $base7;
$blockContentMaxWidth: $base * 102;
$minSearchBlockHeight: 80vh;

// Margins / Paddings
$mainPadding1: 1.5rem;
$mainMargin1: 1.5rem;
$mainMargin2: 3.5rem;
$mainPadding2: 3.5rem;
$mainMargin3: 4.5rem;
$mainPadding3: 4.5rem;

$applicationMargin: 8rem;
$contactBoxMargin: 15vh;
$newsSliderArrow: 1rem;

$contentHeader: 4rem;


$iconBtnHeight: $base * 5.5;
$iconBtnMobileHeight: $base * 4.9;

$btnWidth: $base * 15;
$btnWidthSmall: $btnWidth  $base2;

$containerPadding: $base2;
$containerPaddingSmall: $base1;


$suPagePreviewRatio: 16 / 9;
$partnerRatio: 16 / 9;
$issuuRatio: 16 / 9;
$videoPlayerRatio: 16 / 9;
$blockBgImageRatio: 16 / 8;
$teamMemberRatio: 1 / 1;
$videoRatio: 56.25%;

// Header Sizes
$headerHeight: 6rem;
$headerLogoWidth: 10rem;
$headerLogoWidth2: 13rem;
$headerLogoWidth3: 9rem;

$headerLogoPosition: 1.4rem;
$headerLogoPosition2: 1.4rem;
$headerLogoPosition3: 2.6rem;

$headerMarginLeft: 3.6rem;

// Sidemenu

$sideMenuSpace1: $base2;
$sideMenuSpace2: $base2 + 1;
$sideMenuSpace3: $base2 + 2;

// Icons
$burgerSize: 2.4rem;
$burgerSize2: 3rem;
$burgerSize3: 4rem;
$headerBurgerPosition: 0.2rem;
$headerBurgerPosition2: 0.3rem;
$headerBurgerPosition3: 0.4rem;

$headerHeightViewport: 8vh;
$headerMobileHeight: $base6;
$headerLineSize: 1px;
$headerLineHoverSize: 3px;
$competitionMenuSmallerHeight: $base5;

$applicationIcon1: 2rem;
$applicationIcon2: 3rem;
$applicationIcon3: 4rem;

$scrollArrowPostition: 8rem;
$scrollArrowPostition2: 3rem;

$logoDotSize: $base * 0.9;

$searchThumbWidth: 20%;
$previewThumbWidth: 20%;

$overPageActionCount: 4;

$projectTabProgressHeight: 2px;

$notificationMaxWidth: $blockContentMaxWidth;

// Artilce Sizes
$contentImageSidePadding: 12rem;
$articleWidth: 50rem;

// Article paddings/Margins
$articleHeaderDistance1: 0.5rem;
$articleHeaderDistance2:  1.5rem;
$articleArrowDistance: 1.5rem;

// yes height in vw for reason
$articleImgHeight: 30vw;
$articleImgWidth: 50vw;

$contentImageWidth: 52.5%;
$contentTextWidth: $contentImageWidth;
$contentImgWrapper: 47.5%;
$contentHeaderImgHeight: 80vh;

$contactLineSize: 40%;
$contactText: 52%;
$footerHalfSize: 50%;
// Fonts
$ecosystemLogos: 0.5rem;
