.wld-error {
	align-items: center;
	display: flex;
	flex-direction: column;
	min-height: $fullScreenHeight / 2;
	justify-content: center;

	&__header {
		@include wld-fontSize(h2);
	}

	&__text {
		padding: $base2;
		@include wld-fontSize(medium);
		@include plantinRegular;
	}
}
