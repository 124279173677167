.wld-article {
	@include wld-animateOnIntersection();
	max-width: $base4 * 10;
	padding-bottom: $mainPadding2 / 2;
	padding-top: $base / 2;
	width: 100%;

	&:focus,
	html.mobile &:active,
	html:not(.mobile) &:hover {
		opacity: $hoverOpacity;
	}
	@include media('>small') {
		width: 50%;
		max-width: 100%;
	}

	&:nth-child(even) {
		@include media('>small') {
			padding-left: $base;
		}
	}

	&:nth-child(odd) {
		@include media('>small') {
			padding-right: $base;
		}
	}

	&:nth-child(odd):last-child {
		max-width: 100%;
		@include media('>small') {
			padding-right: 0;
		}
	}

	&__text {
		&--practice {
			@include wld-fontSize(small);
			margin-bottom: $articleHeaderDistance1;
			padding-top: $articleHeaderDistance2;
		}

		&--headline {
			@include wld-fontSize(h2);
			margin: 0;
			@include plantinRegular;
		}
	}

	&__img {
		@include ease(opacity, $easeDuration);
		display: flex;
		flex-wrap: wrap;
		height: $articleImgHeight * 2;
		overflow: hidden;
		@include media('>small') {
			height: $articleImgHeight;
		}

		figure {
			flex: $halfOfScreenWidth * 2;
			height: $articleImgHeight * 2;
			@include media('>small') {
				flex: $articleImgHeight;
				height: $articleImgHeight;
			}
		}

		img {
			width: 100%;
			height: 100%;
		}

		canvas {
			display: none;
		}
	}

	&__arrow {
		margin-right: $base / 2;

		span[data-type="svg"] {
			width: $base;
			@include media('>xLarge') {
				width: $base + 1;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}
	}

	&__linkTile {
		display: flex;
		margin-top: $articleArrowDistance;
	}
}
