.wld-tagSelection {
	@include clearfix;

	&__list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		margin-bottom: -$base2;
		margin-right: -$base2;

		&:not(.wld-js-empty) + &--tags {
			position: relative;
			// border-top: solid currentColor 1px;
			margin-top: $base3;
			padding-top: $base3;

			&::before {
				content: '';
				background-color: currentColor;
				display: block;
				height: 1px;
				left: 0;
				position: absolute;
				right: $base2;
				top: 0;
			}
		}

		&.wld-js-empty {
			display: none;
		}
	}

	&__listElem {
		padding-right: $base2;
		padding-bottom: $base2;

		.wld-tagSelection__list--tags & {}
	}
}
