.wld-body {
	@include ease(transform transform-origin, $easeDuration2);
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;
	transform-origin: 0, 0;
	transform: translateX(0);
	visibility: visible;
	z-index: 2;

	html:not(.ie) & {
		&.wld-js-move {
			transform: translateX($thirdOfScreenWidth * 2);
			@include media('>small') {
				transform: translateX($thirdOfScreenWidth);
			}
		}
	}

	&.wld-js-menuNoScroll,
	&.wld-js-noScroll {
		width: 100%;
		position: fixed;
		overflow: hidden;
	}

	&::after {
		@include position(absolute, 0, 0, 0, 0);
		@include fadeout;
		content: '';
	}

	&.wld-js-covered--menu::after {
		background: $baseBgColor;
	}

	&.wld-js-covered::after,
	body.wld-js-searchMode &::after {
		visibility: inherit;
		z-index: 50;
	}

	&.wld-js-loading::after {
		@include fadein;
		z-index: 2;
		background: $baseBgColor;
	}
}
