.wld-logoList {
	align-content: stretch;
	align-items: stretch;
	background-color: $baseBgColor;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 0;
	justify-content: space-evenly;
	position: relative;

	html.ms & {
		justify-content: space-around;
	}

	&__listElem {
		// align-items: center;
		display: inline-block;
		flex-grow: 0;
		flex-shrink: 0;
		text-align: center;
	}

	&__link {
		@include ease(background-color, $hoverDuration);
		background-color: $baseColor;
		display: block;
		height: 100%;
		padding: $base2;
		position: relative;

		.wld-block--colorSchemeDefault & {}

		.wld-block--colorSchemeAlternative & {
			background-color: $alternativeColor;
		}

		.wld-block--colorSchemeInverted & {
			background-color: $invertedColor;
		}

		&:focus,
		&:hover {
			// background-color: $baseBgColor;
			// .wld-block--colorSchemeDefault & {}
			// .wld-block--colorSchemeAlternative & {
			// 	background-color: $alternativeBgColor;
			// }
			// .wld-block--colorSchemeInverted & {
			// 	background-color: $invertedBgColor;
			// }
			background-color: $logoListHoverBaseBgColor;

			.wld-block--colorSchemeAlternative &,
			.wld-block--colorSchemeDefault & {}

			.wld-block--colorSchemeInverted & {
				background-color: $logoListHoverInvertedBgColor;
			}
		}
	}

	&__logo {
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		img {
			max-height: $base4;
		}
	}
}
