.wld-page {
	@include ease(opacity visibility, $easeDuration4, $pageEaseOut);

	&::after {
		content: '';
		background-color: $overlayColor;
		height: 100%;
		left: -200%;
		opacity: 0;
		position: absolute;
		top: 0;
		visibility: hidden;
		width: 100%;
		z-index: 100;
	}

	&.wld-js-beforeLeaving {
		overflow: hidden;

		&::after {
			height: 300%;
			left: 0;
			z-index: 100;
			visibility: inherit;
			opacity: 1;
		}
	}

	&.wld-js-leaving {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		width: 100%;
		opacity: 0;
		visibility: hidden;
	}

	&.wld-js-entering {
		position: relative;
		z-index: 1;
	}

	&__form {
		* + & {
			margin-top: $base4;
		}
	}
}
