.wld-tagsList {
	&__items {
		margin-bottom: -$base2;

		& + & {
			border-top: 1px solid $baseLineColor;
			margin-top: $base2;
			padding-top: $base2;

			.wld-search & {
				border-top: 1px solid $baseBgColor;
			}
		}
	}

	&__item {
		display: inline-block;
		margin-bottom: $base2;
		margin-right: $base2;

		&:last-child {
			margin-right: 0;
		}

		&.wld-js-hidden {
			display: none;
		}
	}
}
