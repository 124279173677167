// user generated content Style:

.wld-ugc {
	max-width: 100%;
	@include media('>xSmall') {
		max-width: $textMaxWidth1;
	}
	@include media('>xLarge') {
		max-width: $textMaxWidth2;
	}


	img {
		max-width: 100%;
	}

	blockquote,
	q {
		quotes: "»" "«" "\00AB" "\00BB";
	}

	blockquote {
		margin: 0 0 0 1em;

		> p {
			&::before {
				content: open-quote;
			}

			&::after {
				content: close-quote;
			}
		}
	}

	figure {
		&.video {
			position: relative;

			&::before {
				content: '';
				display: block;
				padding-bottom: (1 / $videoPlayerRatio * 100%);
			}

			> iframe {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	ul {
		padding-left: 0;
		margin-bottom: $base2;

		&:last-child {
			margin-bottom: 0;
		}

		> li {
			position: relative;
			margin-left: 1em;
			padding-left: 0.5em;
			list-style-type: disc;
			// &::before {
			// 	content: '•';
			// 	position: absolute;
			// 	left: -1em;
			// }
		}
	}

	a {
		@include ease(border-bottom-color, $hoverDuration);
		border-bottom: 1px solid currentColor;

		&:focus,
		&:hover {
			border-bottom-color: transparent;
		}
	}

	iframe {
		max-width: 100%;
	}

	hr {
		display: block;
		height: 1px;
		border: 0;
		border-top: 1px solid currentColor;
		margin: 1em 0;
		padding: 0;
	}

	h2 + * {
		margin-top: $base3;
		@include media('>small') {
			margin-top: $base5;
		}
	}

	* + h3 {
		margin-top: $base5;
		@include media('>small') {
			margin-top: $base7;
		}
	}

	h2 + h3 {
		margin-top: $base3;
		@include media('>small') {
			margin-top: $base5;
		}
	}

	h3 + * {
		margin-top: $base;
	}

	* + figure {
		margin-top: $base3;
		@include media('>small') {
			margin-top: $base5;
		}
	}

	h3 + figure {
		margin-top: $base2;
		@include media('>small') {
			margin-top: $base3;
		}
	}

	figure + * {
		margin-top: $base3;
		@include media('>small') {
			margin-top: $base5;
		}
	}
}
