.wld-contactLine {
	@include ease(top, $easeDuration2);
	background-color: $baseBgColor;
	display: flex;
	flex-direction: column;
	padding: $base / 2 0;
	top: -1px;
	z-index: 3;
	@include media('>xSmall') {
		flex-direction: row;
	}
	@include media('>xLarge') {
		padding-top: $base;
		padding-bottom: $base;
	}

	&.wld-js-sticky {
		position: sticky;
	}

	&__textWrapper {
		flex-grow: 1;
		padding-bottom: $base / 2;
		padding-left: $mainPadding1;
		padding-right: $mainPadding1;
		@include media('>xSmall') {
			flex-grow: 0;
			width: 50%;
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
		}
		@include media('>xLarge') {
			padding-left: $mainPadding3;
			padding-right: $mainPadding3;
		}
	}

	&__contactWrapper {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		min-width: $contactLineSize;
		padding-left: $mainPadding1;
		padding-right: $mainPadding1;
		@include media('>xSmall') {
			padding-left: $base;
			padding-right: $mainPadding2;
			margin-right: $mainMargin2;
			min-width: 0;
		}

		&:last-child {
			margin-right: $mainMargin2;
			@include media('>xSmall') {
				margin-right: $mainMargin2;
			}
		}
	}

	&__contactGroup {
		display: flex;
		flex-grow: 1;
	}

	&__arrow {
		span[data-type="svg"] {
			width: $base;
			@include media('>xLarge') {
				width: $base + 1;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}
	}

	&__text {
		margin-bottom: 0;

		&--headline {
			@include wld-fontSize(small);
		}

		&--content {
			@include wld-fontSize(small);
		}
	}

	&__link {
		margin-bottom: 0;
		@include wld-fontSize(small);
	}
}
