.wld-preview {
	color: $baseColor;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: $base2;
	position: relative;

	.wld-previewList--winners & {
		padding: $base2 0;
	}

	&__category {
		@include wld-fontSize(small);
		width: 100%;
		margin-bottom: $base;
	}

	&__title {
		width: 100%;
		margin-bottom: $base2;
	}

	&__thumb {
		display: none;

		figure,
		img {
			width: 100%;
		}
		@include media('>small') {
			display: block;
			margin-right: $base2;
			width: $previewThumbWidth;
		}
	}

	&__description {
		width: 100%;
		@include media('>small') {
			width: calc(100% - #{$previewThumbWidth} - #{$base2});
		}
	}
}
