$htmlFontSize: 62.5%;
$htmlFontSizeSmallFactor: 1 !default;

$fontSizeQuote: $base6;
$fontSizeBase: 18;

@function fontSizeBase($value: $fontSizeBase) {
	@return $value / 10 * 1rem;
}

@function fontSizeSmall($value) {
	@return $value / $htmlFontSizeSmallFactor / 10 * 1rem;
}

$fontSizes: (
	base: (
		size: fontSizeSmall(15),
		line-height: 1.45,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(17)),
			'>xLarge': (size: fontSizeBase(23))
		)
	),
	small: (
		size: fontSizeSmall(15),
		line-height: 1.45,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(17)),
			'>xLarge': (size: fontSizeBase(23))
		)
	),
	content: (
		size: fontSizeSmall(17),
		line-height: 1.45,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(20)),
			'>xLarge': (size: fontSizeBase(23))
		)
	),
	medium: (
		size: fontSizeSmall(18),
		line-height: 1.2,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(20)),
			'>xLarge': (size: fontSizeBase(24))
		),
	),
	xMedium: (
		size: fontSizeSmall(21),
		line-height: 1.2,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(25)),
			'>xLarge': (size: fontSizeBase(29))
		),
	),
	big: (
		size: fontSizeSmall(26),
		line-height: 1.3,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(45)),
			'>xLarge': (size: fontSizeBase(66))
		)
	),
	h3: (
		size: fontSizeSmall(18),
		line-height: 1.3,
		margin-bottom: $base,
		variants: (
			'>small': (size: fontSizeBase(29)),
			'>xLarge': (size: fontSizeBase(35))
		)
	),
	h2: (
		size: fontSizeSmall(20),
		line-height: 1.22,
		margin-bottom: $base,
		variants: (
			'>small': (size: fontSizeBase(31)),
			'>xLarge': (size: fontSizeBase(37))
		)
	),
	h1: (
		size: fontSizeSmall(38),
		line-height: 1.3,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(48)),
			'>xLarge': (size: fontSizeBase(58))
		)
	),
	important: (
		size: fontSizeSmall(25),
		line-height: 1.2,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(30))
		)
	),
	caption: (
		size: fontSizeSmall(13),
		line-height: 1.3,
		variants: (
			'>small': (size: fontSizeBase(14))
		)
	),
	smallerCaption: (
		size: fontSizeSmall(13),
		line-height: 1.3,
		variants: (
			'>small': (size: fontSizeBase(13))
		)
	),
	cookie: (
		size: fontSizeSmall(13),
		line-height: 1.45,
		margin-bottom: $base2,
		variants: (
			'>small': (size: fontSizeBase(15)),
			'>xLarge': (size: fontSizeBase(20))
		)
	)
);
