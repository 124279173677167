.wld-logo {
	position: absolute;
	top: $headerLogoPosition;
	z-index: 1;
	@include media('>=small') {
		top: $headerLogoPosition2;
	}
	@include media('>=xLarge') {
		top: $headerLogoPosition3;
	}

	&--sideMenu svg {
		color: $baseColor;
	}

	.wld-header.wld-js-open:not(.wld-js-menuOverflow) &,
	body[data-wld-current-context="menu"] .wld-header:not(.wld-js-menuOverflow) & {
		@include fadein;
	}

	.wld-header:not(.wld-js-menuOverflow).wld-js-competitionMenu & {
		@include media('>small') {
			@include fadeout;
		}
	}

	.wld-header:not(.wld-js-menuOverflow).wld-js-competitionMenu.wld-js-competitionAndMainMenu & {
		@include media('>small') {
			@include fadein;
		}
	}

	.wld-header.wld-js-open &,
	body[data-wld-current-context="menu"] .wld-header.wld-js-open & {
		@include fadeout;
		@include media('>small') {
			@include fadeout;
		}
	}

	&.wld-js-visible {
		visibility: inherit;
		opacity: 1;
		@include ease(opacity visibility, $easeDuration2);
	}

	&__icon {
		@include ease(opacity color, $easeDuration2);
		color: $invertedColor;

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}

		&.wld-js-invertedSvgColor {
			color: $baseColor;
		}

		&.wld-js-invertedSvgColorIntersection {
			color: $baseColor;
		}

		&.wld-js-invertedSvgColorSideMenu {
			color: $baseColor;
		}
	}

	span[data-type="svg"] {
		width: $headerLogoWidth;
		@include media('>=small') {
			width: $headerLogoWidth2;
		}
		@include media('>=xLarge') {
			width: $base6;
		}
		position: unset;
	}
}
