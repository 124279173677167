.wld-main {
	background-color: $baseBgColor;
	flex-grow: 1;
	position: relative;
	z-index: 2;

	&--panel {
		min-height: calc(100vh - #{$base8 * 2});
	}
}
