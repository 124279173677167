.wld-productsTiles {
	background-color: $contentBgColor;
	padding: $mainPadding1 $mainPadding1 $base2;
	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding: $base1 $mainPadding3;
	}

	&__text {
		&--header {
			@include wld-fontSize(h2);
			@include plantinRegular;
			margin-bottom: $base / 2;
		}

		&--category {
			@include wld-fontSize(small);
			margin-bottom: 0.5rem;
		}


		&--headline {
			@include wld-fontSize(h2);
			@include plantinRegular;
			margin-bottom: 1.5rem;
		}

		&--preview {
			@include wld-fontSize(base);
		}
	}

	&__product {
		@include wld-animateOnIntersection();
		max-width: $base4 * 10;
		padding-bottom: $mainPadding2 / 2;
		padding-top: $base / 2;
		width: 100%;

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $hoverOpacity;
		}
		@include media('>small') {
			width: 50%;
			max-width: 100%;
		}

		&:nth-child(even) {
			@include media('>small') {
				padding-left: $base;
			}
		}

		&:nth-child(odd) {
			@include media('>small') {
				padding-right: $base;
			}
		}

		&:nth-child(odd):last-child {
			max-width: 100%;
			@include media('>small') {
				padding-right: 0;
			}
		}
	}


	&__img {
		@include ease(opacity, $easeDuration);
		display: flex;
		flex-wrap: wrap;
		height: $articleImgHeight * 2;
		overflow: hidden;
		margin-bottom: 1.5rem;

		@include media('>small') {
			height: $articleImgHeight;
		}

		figure {
			flex: $halfOfScreenWidth * 2;
			height: $articleImgHeight * 2;
			@include media('>small') {
				flex: $articleImgHeight;
				height: $articleImgHeight;
			}
		}

		img {
			width: 100%;
			height: 100%;
		}

		canvas {
			display: none;
		}
	}


	&__collection {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}


	&__arrow {
		margin-right: $base / 2;

		span[data-type="svg"] {
			width: $base;
			@include media('>xLarge') {
				width: $base + 1;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}
	}

	&__linkTile {
		display: flex;
		margin-top: $articleArrowDistance;
	}

}
