.wld-columns {
	@include media('>small') {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	&__column {
		@include media('>small') {
			flex-grow: 0;
			flex-shrink: 0;
		}

		& + & {
			margin-top: $base4;
			@include media('>small') {
				margin-top: 0;
			}
		}

		&--1_3 {
			@include media('>small') {
				// - 1px fix a bug in IE
				width: calc(33.333% - #{$base3 * 2 / 3} - 1px);
			}
		}

		&--1_2 {
			@include media('>small') {
				// - 1px fix a bug in IE
				width: calc(50% - #{$base3 / 2} - 1px);
			}
		}

		&--2_3 {
			@include media('>small') {
				// - 1px fix a bug in IE
				width: calc(66.666% - #{$base3 * 2 / 3} - 1px);
			}
		}
	}
}
