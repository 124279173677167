.wld-dsgvo {
	padding-top: $mainPadding2;
	padding-bottom: $mainPadding2;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}

	&__text {
		@include wld-fontSize(small);
		@include plantinRegular;
	}
}
