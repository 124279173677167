.wld-sliderNav {
	cursor: pointer;
	display: flex;
	justify-content: center;
	padding-bottom: $mainPadding2;


	&__dot {
		@include ease(background-color, $easeDuration2);
		border-radius: 25px;
		border: 1px solid $baseColor;
		height: $base1Half;
		margin-bottom: $mainMargin2 / 2;
		margin-right: $mainMargin2;
		width: $base1Half;

		&.wld-js-selected {
			background-color: $baseColor;
		}

		&:hover {
			background-color: $baseColor;

			&--invertedColor {
				background-color: $invertedColor;
			}
		}
	}

	&__contentDot {
		@include ease(background-color, $easeDuration2);
		border-radius: 25px;
		border: 1px solid $invertedColor;
		height: $base1Half;
		margin-bottom: $mainMargin2 / 2;
		margin-right: $base1;
		width: $base1Half;

		&.wld-js-selected {
			background-color: $invertedColor;
		}

		&:hover {
			background-color: $invertedColor;

			&--invertedColor {
				background-color: $baseColor;
			}
		}
	}
}
