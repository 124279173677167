@font-face {
	font-family: "Vogue Waald Flat Regular Med";
	src: url("../fonts/WaaldVogue-RegularMed");
	src: url("../fonts/WaaldVogue-RegularMed.woff2") format("woff2"), url("../fonts/WaaldVogue-RegularMed.woff") format("woff");
	font-weight: normal;
}

@font-face {
	font-family: "Vogue Waald Flat Italic";
	src: url("../fonts/WaaldVogue-RegularItalicMed");
	src: url("../fonts/WaaldVogue-RegularItalicMed.woff2") format("woff2"), url("../fonts/WaaldVogue-RegularItalicMed.woff") format("woff");
	font-weight: normal;
}

// @font-face {
// 	font-family: "Plantin Regular";
// 	// Regular
// 	src: url("../fonts/Plantin_Regular");
// 	src: url("../fonts/Plantin_Regular.woff2") format("woff2"), url("../fonts/Plantin_Regular.woff") format("woff");
// 	font-weight: normal;
// 	font-style: normal;
// }

@font-face {
	font-family: "Plantin Italic";
	// Italic
	src: url("../fonts/Plantin_Italic");
	src: url("../fonts/Plantin_Italic.woff2") format("woff2"), url("../fonts/Plantin_Italic.woff") format("woff");
	font-weight: normal;
	font-style: italic;
}
@mixin sansSerif() {
	font-family: "Vogue Waald Flat Regular Med", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@mixin plantinRegular() {
	font-family: "Plantin Regular", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
