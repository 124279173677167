//    ==========================================================================
//    Helper classes
//    ==========================================================================


// * Hide visually and from screen readers:

.hidden {
	display: none !important;
}

// Hide only visually, but have it available for screen readers:
// http://snook.ca/archives/html_and_css/hiding-content-for-accessibility

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}


// Extends the .visuallyhidden class to allow the element
// to be focusable when navigated to via the keyboard:
// https://www.drupal.org/node/897638

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
	clip: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	width: auto;
}


// Hide visually and from screen readers, but maintain layout

.invisible {
	visibility: hidden;
}

// Clearfix: contain floats
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    `contenteditable` attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that receive the `clearfix` class.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.

.clearfix::before,
.clearfix::after {
	content: " "; // 1
	display: table; // 2
}

.clearfix::after {
	clear: both;
}
