.wld-ecosystems {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	padding: $mainPadding2 $mainPadding1;
	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}

	&__bgImg {
		position: absolute;
		bottom: 0;
		left: 0;
		max-width: 100%;
		min-height: 100%;
		right: 0;
		top: 0;
		z-index: -1;

		img {
			min-height: 100%;
			min-width: 100%;
		}
	}

	&__header {
		align-self: flex-start;
		flex-grow: 1;
		margin-bottom: $base2;
		z-index: 2;
	}

	&__linkWrapper {
		align-items: baseline;
		display: flex;
		flex-flow: row;
		z-index: 2;

		p {
			@include ease(opacity, $easeDuration2);
		}

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover p {
			opacity: $iconHover;
		}
	}

	&__link {
		margin-left: $base2;
		z-index: 2;
		outline: 0;
	}

	&__text {
		color: $invertedColor;
		@include media('>medium') {
			max-width: $textMaxWidth1;
		}

		&--thematik {
			@include wld-fontSize(medium);
		}

		&--content {
			@include wld-fontSize(h2);
		}

		&--linkText {
			@include wld-fontSize(h2);
			margin-bottom: $base;
			letter-spacing: $ecosystemLogos;
		}

		&--linkTextGroup {
			@include wld-fontSize(small);
			margin-bottom: $base;
		}
	}
	// &::after {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// 	background-color: $bgFilter;
	// 	z-index: 1;
	// 	width: 100%;
	// 	height: 100%;
	// }
}
