
@mixin simpleFontSize($values) {
	$size: map-get($values, 'size');
	$lineHeight: map-get($values, 'line-height');
	@if $size {
		font-size: $size;
	}

	@if $lineHeight {
		line-height: $lineHeight;
	}
}


@mixin fontSize($fontSizes, $type) {
	$typeName: $type;
	$type: map-get($fontSizes, $type);
	@if ($type) {
		@include simpleFontSize($type);

		$variants: map-get($type, variants);
		@if $variants {
			@each $media, $def in $variants {
				@include media($media...) {
					@include simpleFontSize($def);
				}
			}
		}
	} @else {
		@debug 'Font Size definition not found: ', $typeName;
	}
}
