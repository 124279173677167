.wld-image {
	align-items: center;
	background-color: $contentBgColor;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: $base2 / 2 0 $base2 / 2;

	&__wrapper {
		@include wld-animateOnIntersection();
	}
	@include media('>small') {
		padding: $base4 $contentImageSidePadding;
	}

	img {
		max-width: 100%;
	}

	canvas {
		display: none;
	}

	&__text {
		margin-top: $base;
	}
}
