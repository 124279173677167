.wld-articleList {
	background-color: $contentBgColor;
	padding: $mainPadding1 $mainPadding1 $base2;
	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding: $base1 $mainPadding3;
	}

	&--withBorder {
		border-bottom: 1px solid $borderColor;
	}

	&--altBackground {
		background-color: $invertedColor;
	}

	&__collection {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	&__block {
		width: 50%;
		padding: 0;
	}

	&__loadMore {
		width: 100%;
		text-align: center;
		margin-top: $base / 2;
	}

	&__loadMoreText {
		cursor: pointer;
		@include ease(opacity, $easeDuration2);

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}

		&.wld-js-invisible {
			display: none;
		}
	}

	&__text {
		&--header {
			@include wld-fontSize(h2);
			@include plantinRegular;
			margin-bottom: $base / 2;
		}

		&--description {
			@include wld-fontSize(small);
		}
	}
}
