.wld-applications {
	background-color: $contentBgColor;
	padding-bottom: $mainPadding2;

	&__applicationHeader {
		@include ease(opacity background-color, $easeDuration2);
		background-color: $contentBgColor;
		cursor: pointer;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: $mainPadding2 $mainPadding1;
		@include media('>xSmall') {
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
		}
		@include media('>xLarge') {
			padding-left: $mainPadding3;
			padding-right: $mainPadding3;
		}

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover * {
			opacity: $iconHover;
		}

		&.wld-js-colorSwap {
			background-color: $baseBgColor;
		}
	}

	&__applicationWrapper {
		&.wld-js-border {
			border-top: 1px solid $borderColor;
		}
	}

	&__applicationWrapper[data-wld-collapsed=false] {
		& + & {
			border-top: 1px solid $borderColor;
		}
	}

	&__collapsiable {
		background-color: $baseBgColor;
		padding-left: $mainPadding1;
		padding-right: $mainPadding1;
		@include media('>xSmall') {
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
		}
		@include media('>xLarge') {
			padding-left: $mainPadding3;
			padding-right: $mainPadding3;
		}
	}

	&__icons {
		@include ease(opacity, $easeDuration4);
		display: flex;
		margin-right: $mainPadding2;

		span[data-type="svg"] {
			width: $applicationIcon1;
			@include media('>=small') {
				width: $applicationIcon2;
			}
			@include media('>=xLarge') {
				width: $applicationIcon3;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}

		&--closing {
			span[data-type="svg"] {
				width: $applicationIcon1;
				@include media('>=small') {
					width: $applicationIcon2;
				}
				@include media('>=xLarge') {
					width: $applicationIcon3;
				}

				svg {
					fill: currentColor;
					stroke: currentColor;
				}
			}
		}

		&.wld-js-swap {
			display: block;
		}

		&:not(.wld-js-swap) {
			display: none;
		}
	}

	&__text {
		@include ease(opacity, $hoverDuration);

		&--content {
			@include wld-fontSize (small);
			padding-bottom: $mainPadding2;
		}

		&--header {
			@include wld-fontSize (h2);
		}
	}

	&__link {
		@include ease(opacity, $hoverDuration);
		@include wld-fontSize (small);
		text-decoration: underline;

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}
	}

	&__accordionItemBody {
		position: relative;
		overflow: hidden;
		height: auto;
	}

	&__accordionItemContent {
		@include fadeout();
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding-bottom: $mainPadding2;

		[data-wld-accordion-item][data-wld-collapsed="false"] & {
			@include fadein;
			position: relative;
		}
	}
}
