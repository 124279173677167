.wld-contentHeader {
	background-color: $contentBgColor;
	// padding: $base5;
	&__contentWrapper {
		padding-left: $mainPadding1;
		padding-right: $mainPadding1;
		padding-bottom: $base2;
		position: relative;
		background-color: $contentBgColor;
		z-index: 1;
		// TODO @gb: magic values + looks too much complex, probably not needed, let's talk about this
		@include media('>small') {
			// positon element with padding top + padding bottom + line-height + font-size
			// last number is to balance the lineheight of the second font
			margin-top: -($contentHeader * 2 + 3.66rem + 9.3rem + 2.4rem);
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
		}
		@include media('>xLarge') {
			margin-top: -($contentHeader * 2 + 3.66rem + 11.1rem + 4.2rem);
			padding-left: $mainPadding3;
			padding-right: $mainPadding3;
		}
	}
	// &::after {
	// 	background-color: $bgFilter;
	// 	bottom: 0;
	// 	height: $fullScreenHeight;
	// 	left: 0;
	// 	position: absolute;
	// 	right: 0;
	// 	top: 0;
	// 	width: 100%;
	// 	z-index: 1;
	// }
	&__headlineWrapper {
		padding-top: $contentHeader;
		padding-bottom: $mainPadding2;
	}

	&__contentImage {
		text-align: center;
		overflow: hidden;
		min-height: $fullScreenHeight / 2;
		max-width: $fullScreenWidth;
		@include media('>small') {
			min-height: $fullScreenHeight;
			max-width: $fullScreenWidth;
		}

		img {
			@include objectFit(cover, center);
			height: $fullScreenHeight / 2;
			max-width: 100%;
			object-fit: cover;
			object-position: center;
			width: 100%;
			@include media('>small') {
				width: 100%;
				height: $fullScreenHeight;
			}
		}

		canvas {
			display: none;
		}
	}

	&__text {
		@include wld-fontSize(h2);

		&--alternativeFont {
			@include plantinRegular;
		}
	}
}
