$baseColor: #1D1D1D;
$baseBgColor: #FFFFFF;
$contentBgColor: #F4F4F4;
$baseLineColor: $baseColor;
$linkColor: $baseColor;

$overlayColor: #000;

$invertedColor: $baseBgColor;
$invertedBgColor: $baseColor;
$invertedLineColor: $baseBgColor;

$alternativeColor: $baseColor;
$alternativeBgColor: #F5F5F5;
$alternativeLineColor: $baseColor;


$borderColor: #656464;

$videoBgColor: $baseColor;
$pageTransitionColor: $baseColor;


$bgFilter: transparentize($baseColor, 0.6);
$iconHoverInvert: transparentize($invertedColor, 0.5);


$logoListHoverBaseBgColor: #333333;
$logoListHoverInvertedBgColor: #e5e5e5;

$partnerBgColor: #e5e5e5;

$notificationColor: $baseBgColor;
$notificationBgColor: transparentize($baseColor, 0.1);

$checkIconColor: #00AC8A;

$headerBackgroundColor: $baseColor;

$cookieNotificationBgColor: $baseColor;
$cookieNotificationColor: $baseBgColor;
