.wld-block {
	@mixin removeAdjacentSpace($scheme) {
		&--noBgImg.wld-block--colorScheme#{$scheme}:not(.wld-blockTilesWall__tile) + &--noBgImg.wld-block--colorScheme#{$scheme},
		&--noBgImg.wld-block--colorScheme#{$scheme}:not(.wld-blockTilesWall__tile) + &--publication.wld-block--colorScheme#{$scheme},
		&--noBgImg.wld-block--colorScheme#{$scheme}:not(.wld-blockTilesWall__tile) + &--video.wld-block--colorScheme#{$scheme},
		&--publication.wld-block--colorScheme#{$scheme} + &--noBgImg.wld-block--colorScheme#{$scheme}:not(.wld-blockTilesWall__tile),
		&--video.wld-block--colorScheme#{$scheme} + &--noBgImg.wld-block--colorScheme#{$scheme}:not(.wld-blockTilesWall__tile) {
			padding-top: 0;
		}
	}
	@mixin removeExtraTileSpace($scheme) {
		&--tilesWall.wld-block--colorScheme#{$scheme} {
			.wld-block--noBgImg.wld-block--colorScheme#{$scheme},
			.wld-block--publication.wld-block--colorScheme#{$scheme},
			.wld-block--videoWithoutContent.wld-block--colorScheme#{$scheme} {
				padding: 0;
			}
		}
	}
	max-width: none;
	display: block;
	position: relative;
	overflow: hidden;
	color: $baseColor;
	padding: $blockMobileVerticalPadding $mainPadding2;
	// @include media('>small') {
	// 	padding-top: $blockVerticalPadding;
	// 	padding-bottom: $blockVerticalPadding;
	// }
	//
	// &--noPadding {
	// 	padding: 0;
	// 	margin: 0;
	// }
	// remove adjacent margins when they have the same colors
	@include removeAdjacentSpace('Default');
	@include removeAdjacentSpace('Inverted');
	@include removeAdjacentSpace('Alternative');

	&__titleImage + .wld-blocks__subBlocks > &--noBgImg.wld-block--colorSchemeDefault:first-child,
	&__titleImage + .wld-blocks__subBlocks > &--publication.wld-block--colorSchemeDefault:first-child,
	&__titleImage + .wld-blocks__subBlocks > &--video.wld-block--colorSchemeDefault:first-child {
		padding-top: 0;
	}

	.wld-page--projectEdit & {
		overflow: visible;
	}
	@include removeExtraTileSpace('Default');
	@include removeExtraTileSpace('Inverted');
	@include removeExtraTileSpace('Alternative');

	.wld-block--tilesWall > .wld-block__content > &--videoWithoutContent {
		padding: 0;
		@include media('>small') {
			padding: 0;
		}
	}

	&--noBgImg.wld-block--colorSchemeAlternative,
	&--noBgImg.wld-block--colorSchemeDefault,
	&--noBgImg.wld-block--colorSchemeInverted {
		&:not(.wld-blockTilesWall__tile) + &:not(.wld-blockTilesWall__tile) {
			padding-top: 0;
		}
	}

	&--tile {
		margin-bottom: $base2;
		@include media('>small') {
			margin-bottom: $base3;
			margin-left: 0;
			margin-right: 0;
		}
	}

	&--alignLeft {
		text-align: left;
	}

	&--alignCenter {
		text-align: center;
	}

	&--alignRight {
		text-align: right;
	}
	@include wld-blocksFirstBlock() {
		.wld-body & {
			// TODO: find other value for this insead of vh
			padding-top: #{$blockMobileVerticalPadding};
			@include media('>small') {
				padding-top: #{$blockVerticalPadding};
			}
		}

		.wld-overlay & {
			padding-top: 0;
			// padding-top: $containerPaddingSmall;
			@include media('>small') {
				padding-top: 0;
				// padding-top: $containerPadding;
			}
		}
	}

	.wld-blocks--columns & {
		padding-top: $containerPaddingSmall;
		padding-bottom: $containerPaddingSmall;
		@include media('>small') {
			padding-top: $containerPadding;
			padding-bottom: $containerPadding;
		}
	}

	.wld-block--tilesWall & {
		padding-top: $base;
		padding-bottom: $base;
		@include media('>small') {
			padding-top: $base2;
			padding-bottom: $base2;
		}
	}

	.wld-blocks--pageActions &:nth-last-child(2) {
		padding-bottom: 0;
		@include media('>small') {
			padding-bottom: 0;
		}
	}

	&--search,
	&--teaser {
		padding-top: $blockMobileVerticalPadding + $base3;
		padding-bottom: $blockMobileVerticalPadding + $base3;
		@include media('>small') {
			padding-top: $blockVerticalPadding + $base6;
			padding-bottom: $blockVerticalPadding + $base6;
		}

		.wld-block--tilesWall & {
			padding-top: $blockMobileVerticalPadding;
			padding-bottom: $blockMobileVerticalPadding;
			@include media('>small') {
				padding-top: $blockVerticalPadding;
				padding-bottom: $blockVerticalPadding;
			}
		}
	}

	&--pageActions {
		padding-top: 0;
		@include media('>small') {
			padding-top: 0;
		}
	}

	&--tagSelection {
		padding-top: $blockMobileVerticalPadding + $base1;
		padding-bottom: $base1;
		@include media('>small') {
			padding-top: $blockVerticalPadding + $base1;
			padding-bottom: $base1;
		}
	}

	&--bgImg {
		min-height: 100vw / $blockBgImageRatio;

		&.wld-block--linkedTile,
		&.wld-block--publication,
		&.wld-block--video,
		.wld-block--tilesWall & {
			min-height: 0;
		}
	}

	&--linkedTile {
		&,
		.wld-block--tilesWall & {
			padding-top: $blockMobileVerticalPadding + $base8;
			padding-bottom: $blockMobileVerticalPadding + $base8;
			@include media('>small') {
				padding-top: $blockVerticalPadding + $base10;
				padding-bottom: $blockVerticalPadding + $base10;
			}
		}
	}

	&__mainLink {
		&::before {
			content: '';
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}

	&__pageTitle {
		@include wld-fontSize(h1);
		margin-bottom: $base3;
		@include media('>small') {
			margin-bottom: $base5;
		}
	}

	&__titleImage {
		text-align: center;
		margin-bottom: $base7;

		img {
			width: 100%;
		}
	}

	&__subTitle {
		@include wld-fontSize(h2);
		display: block;

		.wld-block--tilesWall & {
			@include wld-fontSize(base);
		}

		.wld-block__title + &,
		.wld-block__titleImage + & {
			margin-bottom: $base2;
		}
	}
	//NOTE: generalize likList!
	&__links {
		margin-left: auto;
		margin-right: auto;
		margin-top: $base3;
		@include media('>xSmall') {
			margin-top: $base7;
		}
	}

	&__linkItem {
		max-width: 100%;
		margin-bottom: $base;
		@include media('>xSmall') {
			display: inline-block;
			max-width: calc(100% - #{$base});
			margin-left: $base * 0.5;
			margin-right: $base * 0.5;
		}
	}

	&__logos {
		margin-top: $base5;
		max-width: $textMaxWidth1;
		@include media('>xSmall') {
			margin-top: $base10;
		}
		// .wld-block__links + & {
		// 	@include media('>xSmall') {
		// 		margin-top: $base3;
		// 	}
		// }
	}

	&__icon {
		text-align: center;
		padding-top: $base2;
		@include media('>xSmall') {
			padding-top: $base5;
		}

		span[data-type="svg"] {
			width: $base3;
			@include media('>xSmall') {
				width: $base5;
			}
		}
	}

	&__articleList {
		border-top: solid 1px $baseColor;
	}
}
@import "./block-elements/content";
@import "./block-elements/image";
@import "./block-elements/title";
@import "./block-elements/text";
@import "./block-elements/link";
