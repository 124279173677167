
@mixin wld-animateOnIntersection() {
	html:not(.ie) & {
		@include ease(background-color transform opacity, $easeDuration2 * 1.5);
		opacity: 0;
		transform: translateY(10vh);

		body.wld-js-highContrast &,
		&.wld-js-insideViewport {
			opacity: 1;
			transform: none;
		}
	}
}
