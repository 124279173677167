.wld-menu {
	&--main {
		.wld-header:not(.wld-js-menuOverflow) & {
			@include media('>small') {
				//overflow: hidden;
				//margin-bottom: -$headerLineSize;
			}
		}
	}

	&__list {
		// word-break: keep-all;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		height: 100%;
		justify-content: space-evenly;
		text-align: right;
		white-space: nowrap;

		html.ms & {
			justify-content: space-around;
		}

		.wld-menu--main & {
			// word-break: keep-all;
			height: 100%;
			text-align: right;
			white-space: nowrap;
		}

		.wld-menu--competition & {
			// flex-grow: 0;
			//margin-bottom: -$headerLineSize;
			display: block;
		}
	}

	&__listElem {
		.wld-header:not(.wld-js-menuOverflow) & {
			@include media('>small') {
				@include wld-fontSize(base);
				display: inline-block;
				height: 100%;
				position: relative;
			}
		}
	}

	&__link {
		display: block;
		padding: $base;

		.wld-menu--competition & {
			.wld-header.wld-js-competitionAndMainMenu:not(.wld-js-menuOverflow) & {
				@include media('>small') {
					//height: $competitionMenuSmallerHeight;
				}
			}
		}

		.wld-menu--main .wld-menu__listElem:first-child & {
			border-top: 0;

			&:focus,
			&:hover {
				border-top-color: currentColor;
			}
		}
	}

	&__linkLabel {
		@include ease(border-bottom-color, $hoverDuration);
		display: block;
		position: relative;
		top: 50%;
		transform: translateY(-50%);

		&:focus,
		&:hover {
			border-bottom: 1px solid currentColor;
		}

		.wld-menu--competition &,
		.wld-menu--main & {
			&:focus,
			&:hover {
				border-bottom: 0;
			}
		}
	}
}
