.wld-block {
	&__content {
		position: relative;
		z-index: 1;
		display: block;
		// max-width: $blockContentMaxWidth; //$mainxMaxWidth;
		// width: 100%;
		padding: $mainPadding2;

		&--hasBackground {
			background-color: $contentBgColor;
		}

		.wld-block--alignRight & {
			text-align: right;
		}


		.wld-block--tilesWall > & {
			margin-bottom: -$base2;

			@include media('>small') {
				margin-bottom: -$base3;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}


		.wld-blockTilesWall--widthMax > & {
			max-width: none;
		}


		.wld-block--publication & {
			height: auto;
			//margin-top: $base2;

			&::before {
				display: none;
			}

			.wld-blocks > & {
				@include media('>small') {
					height: 0;
					padding: 0 $base3;
				}
			}

			.wld-blocks > &::before {
				@include media('>small') {
					content: '';
					display: block;
					height: $base3;
				}
			}
		}

		.wld-block--video & {
			order: 1;
		}


		.wld-overlay & {
			max-width: none;
		}
	}
}
