.wld-block {
	&__bgImg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		max-width: 100%;
		min-height: 100%;

		img {
			min-height: $fullScreenHeight;
			min-width: $fullScreenWidth;
		}
	}



	&__contentImg {
		min-height: 50vh;
		max-width: 50vw;

		img {
			width: 100%;
		}
	}

	&__titleImg {
		display: flex;
		justify-content: center;
		overflow: hidden;
		max-width: 100%;
		max-height: 100%;

		img {
			min-width: $fullScreenWidth;
			height: 100%;
		}
	}
}
