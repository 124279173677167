$buttonBorderOpacity: 0.4;
$schoolBkgOpacity: 0.8;

$easeDuration: 0.2s;
$easeDuration2: $easeDuration * 2;
$easeDuration3: $easeDuration * 3;
$easeDuration4: $easeDuration * 4;
$easeDuration6: $easeDuration * 6;

$videoHoverDuration: $easeDuration * 1.5;

$easeFunction: ease-out;
$easeOutCubic: 'cubic-bezier(0.22, 0.61, 0.36, 1)';
$pageEaseOut: 'cubic-bezier(0,.72,.09,.64)';


$menuTranslation: 10em;

$hoverDuration: $easeDuration;

$disabledFieldOpacity: 0.5;
$hoverOpacity: 0.7;
$iconHover: 0.6;


$blockBgImgOpacity: 0.6;
$blockBgImgCaptionOpacity: 0.7;
