.wld-blockVideo {
	background-color: $contentBgColor;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-bottom: $mainPadding2;
	padding-top: $mainPadding2;
	position: relative;
	@include media('>xSmall') {
		padding: $base4 $contentImageSidePadding;
	}

	&__bgImg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;


		img {
			@include objectFit(cover, center);
			width: 100%;
			height: 100%;
		}
	}

	&__bgImgCaption {
		position: absolute;
		bottom: $base;
		left: $base;

		@include media('>xSmall') {
			bottom: $base2;
			left: $base2;
		}
	}

	&__playerBtn {
		@include wld-animateOnIntersection();
		bottom: 100%;
		color: $invertedColor;
		display: block;
		left: 0;
		width: 100%;
		position: relative;

		&:active,
		html:not(.mobile) &:focus,
		html:not(.mobile) &:hover {
			background-color: transparentize($invertedBgColor, 0.5);
		}

		&::before {
			content: '';
			display: block;
			padding-bottom: (1 / $videoPlayerRatio * 100%);
		}

		.wld-js-visiblePlayer & {
			display: none;
		}

		.wld-blockVideo:not(.wld-block--bgImg) & {
			background-color: $invertedBgColor;
		}
	}

	&__playerBtnIcon {
		display: block;
		left: 50%;
		position: absolute;
		text-align: center;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 100%;

		span[data-type="svg"] {
			max-width: 20%;
			height: $base2;
			@include media('>xSmall') {
				height: $base3;
			}
		}
	}

	&__player {
		bottom: 100%;
		display: none;
		left: 0;
		width: 100%;

		.wld-js-visiblePlayer & {
			display: block;
		}

		figure {
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}

			&::before {
				content: '';
				display: block;
				padding-bottom: (1 / $videoPlayerRatio * 100%);
			}

			> iframe {
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}
}
