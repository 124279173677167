.wld-info {

	&__adressSegment {
		margin-bottom: $mainMargin2 / 2;
	}

	&__text {
		&--content {
			@include wld-fontSize(h2);
			@include plantinRegular;
			@include media('>xLarge') {
				max-width: $textMaxWidth1;
			}
		}

		&--contactText {
			@include wld-fontSize(small);
		}
	}
}
