.wld-header {
	// width: 100%;
	color: $invertedColor;
	left: 0;
	max-width: none;
	min-height: $headerHeight;
	padding-bottom: $base / 2;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;
	padding-top: $base / 2;
	position: absolute;
	right: 0;
	top: 0;
	transform: translateY(0%);
	visibility: inherit;
	z-index: 3;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding: $base1 $mainPadding3;
	}

	&--backgroundColor {
		background-color: $invertedColor;
	}

	&.wld-js-fixed {
		// @include ease(top, $easeDuration2);
		top: -20rem;
		position: fixed;
	}

	&.wld-js-invertedColor {
		@include ease(color, $easeDuration);
		// background-color: $baseColor;
		color: $baseColor;
	}

	&.wld-js-invertedSvgColorIntersection {
		@include ease(color, $hoverDuration);
		color: $baseColor;
	}

	&.wld-js-invertedSvgColorSideMenu {
		@include ease(color, $hoverDuration);
		color: $baseColor;
	}

	&.wld-js-backgroundColor {
		background-color: $invertedColor;
	}

	&.wld-js-showMenu {
		@include ease(top background-color, $easeDuration2);
		top: 0;
	}

	&.wld-js-scrolledDown {
		transform: translateY(-100%) translateY(-2px);

		body.wld-js-editProject & {
			transform: none;
		}
	}

	&.wld-js-open {
		position: relative;
		transform: none;
		border-bottom: 0;
		padding-bottom: $base4;
	}

	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: flex-end;
	}

	&__icon {
		position: relative;
		@include ease(color opacity background-color, $easeDuration2);
		border: 0;
		color: $invertedColor;
		cursor: pointer;
		display: inline-block;
		outline: none;

		&--black {
			color: $baseColor;
		}

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}

		&.wld-js-invertedSvgColor {
			color: $baseColor;
		}

		&.wld-js-invertedSvgColorIntersection {
			color: $baseColor;
		}

		&.wld-js-invertedSvgColorSideMenu {
			color: $baseColor;
		}

		&.wld-js-swap {
			display: block;
		}

		&:not(.wld-js-swap) {
			display: none;
		}

		span[data-type="svg"] {
			top: $headerBurgerPosition;
			width: $burgerSize;
			@include media('>=small') {
				top: $headerBurgerPosition2;
				width: $burgerSize2;
			}
			@include media('>=xLarge') {
				top: $headerBurgerPosition3;
				width: $burgerSize3;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}
	}
}
