.wld-button {
	@include button($base);
	@include ease(color background-color border-color, $hoverDuration);
	background-color: $linkColor;
	border: 1px solid transparent;
	color: $baseBgColor;
	font-weight: normal;
	padding: $base / 2 $base;
	position: relative;
	width: 100%;

	@include media('>small') {
		width: auto;
		min-width: $btnWidth;
	}

	&--upload {
		overflow: hidden;
	}

	&--accountSecondary,
	&--seamless {
		background-color: transparent;
		border-color: transparent;
		border-width: 0 0 0 0;
		color: currentColor;
		min-width: auto;
		width: auto;
	}

	&--seamless {
		padding-left: 0;
		padding-right: 0;
	}

	&--accountSecondary {
		padding: 0;
	}

	&:active,
	&:focus,
	&:hover {
		color: $linkColor;
		background-color: $baseBgColor;
		border-color: $linkColor;
	}

	.wld-blockTilesWall & {
		@include media('screen') {
			width: 100%;
			min-width: 0;
		}
	}

	.wld-pagination & {
		min-width: 0;
	}

	&__icon {
		height: 1em;

		&--flipH {
			transform: scaleX(-1);
		}

		svg {
			fill: currentColor;
		}
	}

	&__label {
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;

		.wld-button--accountSecondary:focus &,
		.wld-button--seamless:focus &,
		html.mobile .wld-button--accountSecondary:active &,
		html.mobile .wld-button--seamless:active &,
		html:not(.mobile) .wld-button--accountSecondary:hover &,
		html:not(.mobile) .wld-button--seamless:hover & {
			border-bottom-color: currentColor;
		}

		.wld-form & {
			text-align: left;
		}
	}

	&--colorSchemeAlternative {
		&:active,
		&:hover {
			background-color: $alternativeBgColor;
		}
	}

	&--colorSchemeInverted {
		background: transparent;
		border: 1px solid $alternativeBgColor;

		&:active,
		&:focus,
		&:hover {
			border-color: $alternativeBgColor;
		}
	}
}
