.wld-pagination {
	.wld-search &,
	.wld-winners & {
		padding: $base4 0;
	}

	.wld-search & {
		background-color: $baseBgColor;
	}

	&__list {
		display: flex;
		flex-direction: row;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__listElem {
		display: none;
		@include media('>small') {
			display: block;
		}

		&--arrow,
		&--current {
			display: block;
		}
	}

	&__tile {
		margin-left: $base * 0.5;
		margin-right: $base * 0.5;
		border: solid $baseColor 1px;
		background-color: $baseBgColor;
		color: $baseColor;
		@include ease(background-color, color, $hoverDuration);

		&:focus,
		&:hover {
			background-color: $invertedBgColor;
			color: $invertedColor;
		}

		&--current {
			cursor: auto;
			border: solid $invertedBgColor 1px;
			background-color: $invertedBgColor;
			color: $invertedColor;
		}
	}

	&__tileSpacer {
		cursor: auto;
		margin-left: $base * -0.5;
		margin-right: $base * -0.5;
		@include ease(background-color, $hoverDuration);

		&,
		&:hover {
			border: solid transparent 1px;
			background-color: $baseBgColor;
			color: $baseColor;
		}
	}
}
