.wld-videoHeader {
	background-color: $headerBackgroundColor;
	overflow: hidden;
	width: 100%;
	height: $fullScreenHeight;
	position: relative;
	@include media('>xSmall') {
		height: 0;
		padding-bottom: $videoRatio;
	}
	@include media('>xLarge') {
		height: 100%;
		padding-bottom: $fullScreenHeight;
	}

	&__scrollIcon {
		align-items: center;
		bottom: $scrollArrowPostition;
		color: $invertedColor;
		display: flex;
		flex-flow: column;
		justify-content: center;
		left: 50%;
		position: absolute;
		right: 50%;
		transform: translate(50% 50%);
		z-index: 3;

		@include media('>xSmall') {
			bottom: $scrollArrowPostition2;
		}

		span[data-type="svg"] {
			width: $base / 2;
			@include media('>=small') {
				width: $base / 2;
			}

			svg {
				fill: currentColor;
				stroke: currentColor;
			}
		}
	}


	&__player {
		display: none;
		height: $fullScreenHeight;
		width: $fullScreenHeight * 16 / 9;
		left: 50%;
		transform: translateX(-50%);

		@include media('>xSmall') {
			display: none;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			transform: translateX(0);
			width: 100%;
		}

		@include media('>xLarge') {
			height: 200%;
			width: $fullScreenWidth;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}


		> iframe {
			width: 100%;
			height: 100%;

		}

		.wld-js-visiblePlayer &,
		.wld-videoHeader--visiblePlayer & {
			display: block;
		}
	}

	&__text {
		@include wld-fontSize(small);
	}

	&__videoOverlay {
		@include plantinRegular;
		@include ease(opacity visibility, $easeDuration2);
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		padding-left: $mainPadding1;
		padding-right: $mainPadding1;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 1;
		@include media('>xSmall') {
			padding-left: $mainPadding2;
			padding-right: $mainPadding2;
		}
		@include media('>xLarge') {
			padding-left: $mainPadding3;
			padding-right: $mainPadding3;
		}
	}

	&__overlayText {
		color: $invertedColor;
		max-width: $textMaxWidth1;
		text-align: center;
		@include wld-fontSize(h1);
	}
}
