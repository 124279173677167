.wld-cookieNotification {
	@include ease(opacity visibility, $easeDuration2);
	background-color: $cookieNotificationBgColor;
	bottom: 0;
	color: $cookieNotificationColor;
	left: 0;
	max-width: none;
	opacity: 0;
	padding-bottom: $base;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;
	padding-top: $base;
	position: fixed;
	visibility: hidden;
	width: 100%;
	z-index: 500;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}


	&.wld-js-visible {
		opacity: 1;
		visibility: inherit;
	}


	&__content {
		max-width: $blockContentMaxWidth / 2;
		margin: auto;
		@include media('>small') {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
		}
	}


	&__text {
		@include wld-fontSize(cookie);
		margin-bottom: $base;

		@include media('>small') {
			margin-bottom: 0;
			margin-right: $base2;
			flex-shrink: 1;
			flex-grow: 1;
		}
	}

	&__button {
		@include media('>small') {
			flex-shrink: 0;
			flex-grow: 0;
		}
	}
}
