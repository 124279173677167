.wld-block {
	&__title {
		@include wld-fontSize(h1);
		display: block;

		.wld-block--tilesWall & {
			@include wld-fontSize(h2);
		}

		.wld-block--video & {
			margin-top: $base2;

			@include media('>small') {
				margin-top: $base3;

				.wld-overlay & {
					margin-top: $base5;
				}
			}
		}

		.wld-block--tile.wld-block--publication & {
			margin-top: $base2;

			@include media('>small') {
				margin-top: $base3;
			}
		}
	}
}
