.wld-blocks {
	grid-template-columns: 50% 50%;

	&__subBlocks {
		.wld-columns & {
			margin-left: -$containerPaddingSmall;
			margin-right: -$containerPaddingSmall;
			@include media('>small') {
				margin-left: -$containerPadding;
				margin-right: -$containerPadding;
			}
		}
	}
}
