.wld-linkList {
	margin-bottom: 0;
	padding-top: $mainPadding2;
	padding-bottom: $mainPadding2;
	padding-left: $mainPadding1;
	padding-right: $mainPadding1;

	@include media('>xSmall') {
		padding-left: $mainPadding2;
		padding-right: $mainPadding2;
	}
	@include media('>xLarge') {
		padding-left: $mainPadding3;
		padding-right: $mainPadding3;
	}

	&__internalLinkSection {
		padding-top: $mainPadding2 / 2;
	}

	&__link {
		@include wld_fontSize(small);
		margin-right: $mainMargin2 / 2;

		@include ease(opacity, $easeDuration2);

		&:focus,
		html.mobile &:active,
		html:not(.mobile) &:hover {
			opacity: $iconHover;
		}
	}
}
